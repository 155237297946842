<template>
  <div>
    <div class="loginbac">
      <div class="w1400 clearfix">
        <div class="register fr">
          <p class="regtitle"><span>账号密码登录</span></p>
          <ul>
            <li class="clearfix">
              <img src="~assets/img/mobile.png" />
              <Input
                v-model="mobile"
                class="denglu"
                @blur.native.capture="getNumber"
                size="large"
                placeholder="请输入11位手机号码"
              />
            </li>
            <li class="clearfix">
              <img src="~assets/img/unlock.png" />
              <Input
                v-model="password"
                type="password"
                class="denglu"
                size="large"
                placeholder="请输入登录密码"
              />
            </li>
            <li class="clearfix">
              <Button
                type="primary"
                :loading="loading"
                style="width: 100%; font-size: 16px"
                @click="login"
              >
                <span v-if="!loading">立即登录</span>
                <span v-else>登陆中...</span>
              </Button>
            </li>
          </ul>
          <div class="zhanghu clearfix">
            <router-link
              class="fcolor"
              :to="{ name: 'register', params: { type: 1 } }"
              >忘记密码</router-link
            ><router-link
              class="fcolor"
              :to="{ name: 'register', params: { type: 2 } }"
              >免费注册</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeadlogin from '@/components/content/mainTabbar/NavHeadlogin'
import LogoBar from '@/components/content/mainTabbar/LogoBar'

import { loginApi } from 'network/login'

import { JSEncrypt } from 'jsencrypt'
export default {
  name: 'Login',
  data() {
    return {
      mobile: '', //手机号
      password: '',
      loading: false,
    }
  },
  components: {
    NavHeadlogin,
    LogoBar,
  },
  created() {
    localStorage.removeItem('token')
    localStorage.removeItem('userName')
    localStorage.removeItem('setVip')
    localStorage.removeItem('fxVip')
    this.mobile = localStorage.getItem('mobile')
    var that = this
    document.onkeydown = function (e) {
      var key = window.event.keyCode
      if (key == 13) {
        that.login()
      }
    }
  },
  methods: {
    // 手机号验证
    getNumber() {
      if (this.mobile === '') {
        this.$Message.warning('请输入手机号')
        return false
      } else {
        if (
          !/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
            this.mobile
          )
        ) {
          this.$Message.warning('手机号格式不正确')
          return false
        }
      }
    },
    login() {
      this.loading = true
      if (this.mobile == '' || this.password == '') {
        this.$Message.warning('用户名或者密码不能为空')
        this.loading = false
      } else {
        const encryptor = new JSEncrypt()
        encryptor.setPublicKey(this.$config.pubKey) // 设置公钥
        let rsaPassWord = encryptor.encrypt(this.password)
        let data = {
          userName: this.mobile.trim(),
          password: rsaPassWord,
        }
        loginApi(data).then((res) => {
          if (res.code === 0) {
            // 设置 token
            localStorage.setItem('token', res.key)
            this.$store.commit('setToken', res.key)
            // 保存登录名
            localStorage.setItem('userName', data.userName)
            localStorage.setItem('setVip', res.data)
            localStorage.setItem('fxVip',res.count)
            // localStorage.setItem('fxVip',1)
            // localStorage.setItem('setVip', 1)
            this.$Message.success('登录成功！')
            this.$router.push({ path: '/home' })
          } else {
            this.$Message.warning('请输入正确的手机号或密码！')
            this.loading = false
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.fcolor {
  color: #25449a;
}
.loginbac {
  background: url('~assets/img/loginbg.png') no-repeat;
  height: 540px;
  background-position: 20% top;
}
.register {
  width: 470px;
  background: #fff;
  padding: 10px 25px 160px;
  margin: 20px;
  border-radius: 5px;
}
.register .regtitle {
  font-size: 16px;
  line-height: 60px;
  border-bottom: 1px solid #e6ebf5;
}
.register ul li {
  margin-top: 30px;
  position: relative;
}
.register ul li img {
  position: absolute;
  top: 12px;
  left: 18px;
  z-index: 10;
}
.register .regtitle span {
  position: relative;
  top: 1px;
  display: inline-block;
  border-bottom: 2px solid #25449a;
}
.zhanghu {
  margin-top: 13px;
}
.zhanghu a {
  font-size: 14px;
}
.zhanghu a:nth-child(1) {
  float: left;
}
.zhanghu a:nth-child(2) {
  float: right;
}
</style>
<style>
.denglu .ivu-input-large {
  height: 48px;
  background: #e6ebf5;
  padding-left: 50px;
}
.ivu-input-large::-webkit-input-placeholder {
  color: #787a80;
}
.ivu-input-large::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #787a80;
}
.ivu-input-large:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #787a80;
}
.ivu-input-large:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #787a80;
}
.ivu-btn-primary {
  height: 48px;
  font-size: 12px;
  color: #fff;
  background: #25449a;
}
</style>
